import React, { useState, useEffect } from "react";
import { deepCopy, preJS } from "../../AppExPf/AppUtil";
import DoEx from "../../AppExPf/ATExerEdit/DoEx";
import { CpECtnViews } from "../../AppExPf/ATExerEdit/TabExerContent";
import { useMediaCache } from "../../AppExPf/utils/useMediaCache";
import { useQtnCache } from "../../AppExPf/utils/useQtnCache";
import { useCtnLang, useUILang } from "../../AppExPf/utils/useUILang";
import { toAry, toObj } from "../../libs/libType";
import { stopEvent } from "../../libs/libUI";
import { IconList } from '../../consts/ATIconListToUsePoc';
import { ReduxBind } from "../../saga/ReduxState";
import LayerExNew from "./LayerExNew";
import CpIco from "../_components/CpIco";
import CpLanguageSwitcher from "../_components/CpLanguageSwitcher";
import { exHasQtn } from "./PageExerciseEdit";
import { LayerLocalMedia } from "../../AppExPf/ATMediaPool/LayerLocalMedia";

const TabExercise = ReduxBind(props => { //base on poc PreviewExercise
    const {subjMetas, setParaDel, reload, goPopExer, eset, exers, EId, dispatch} = props;
    const userId = props.userId();// 'teacher'; //searchParams.get("role") || "teacher"
    const [qtns, useCacheQtns] = useQtnCache(dispatch, 0);
    const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, dispatch, 0);

    const [t, uiEn, UILang, setUILang] = useUILang();
    const [_showEn, ct, filpCtnL, ctnL, setCtnL] = useCtnLang(props.ctnL || UILang);
    const [newSrc, setNewSrc] = useState();

    const hasEx = exers[EId];
    const ex = toObj(hasEx);

    const [canEn, canCt] = [(!eset) || eset.ESLangEn, (!eset) || eset.ESLangCt];
    const [ECtns, hasEn, hasCt] = [toAry(ex.ECtns), canEn && ex.ELangEn, canCt && ex.ELangCt];
    const CtnL2 = hasCt && hasEn;
    const langOk = (_showEn? hasEn: hasCt) || ((!hasEn) && (!hasCt));
    const showEn = (!hasCt || (_showEn && hasEn));
    useEffect(()=>{ langOk || filpCtnL(); }, [hasEn, hasCt]);

    if(!hasEx) return '';

    const clickPopEx = (edit, assign) => e => { stopEvent(e); goPopExer(ex, edit? deepCopy(ex): 0, showEn, assign, 0); };

    const handleCopyEditExercise = e => { stopEvent(e); setNewSrc(ex); };
    const handleDeleteExercise = e => { stopEvent(e); setParaDel({EId, onDel:(()=>reload())}); };
    const handleAssignExercise = clickPopEx(0, 1);
    const handleEditExercise = clickPopEx(1, 0);
    const handleOpenExercise = clickPopEx(0, 0);

    const isMyEx = (ex.modUid === userId);

    const exerciseActionBtnList = [
        { title: "open", handle: handleOpenExercise, iconPath: IconList.general.open }, 
        (isMyEx) && { title: "edit", handle: handleEditExercise, iconPath: IconList.general.edit },
        { title: "copy-edit", handle: handleCopyEditExercise, iconPath: IconList.general.copy }, 
        (isMyEx) && { title: "delete", handle: handleDeleteExercise, iconPath: IconList.general.trash }, 
        { title: "assign", handle: handleAssignExercise, iconPath: IconList.general.assign }
    ];

    const setOnAddMedia = () => {alert('Front Preview Not Support Media Pool')};
    const pvProps = {...props, close:()=>{}, ECtns, preview:1, PVMode:2, showResult:0, showPub:1, lock:1, 
        showEn, hasEn, hasCt, qtns, useCacheQtns, setOnAddMedia, mediaDLs, getMediaDLs, addLocalMedias,  };

    return <>
        <div className={"px-2 py-1 d-flex justify-content-between align-items-center gap-2"}>
            {CtnL2? <span className={"d-flex mx-2 mx-md-4"}>
                <CpLanguageSwitcher en={showEn} flipLang={filpCtnL} style={"simple-switch-quick-action-theme"}/>
            </span>: '' }
            {CtnL2? <div className={"vr"}></div>: ''}
            <div className={"exercise-quick-action-btn-container d-grid justify-content-evenly flex-wrap  overflow-hidden gap-2 flex-grow-1"} >
                {exerciseActionBtnList.map((btn, i) => {
                    if(!btn) return '';
                    const {title, iconPath, handle} = btn;
                    const showDim = !exHasQtn(ex) && (title === 'assign');
                    return <span key={i} className={"btn exercise-quick-action-btn " + (showDim?" text-dim-400 pe-none ":"")} onClick={handle}>
                        <span role={"button"} className={"d-flex flex-column gap-1 justify-content-center align-items-center rounded semi-bold "}>
                            <span className={"fs-5"}><CpIco src={iconPath}/></span>
                            <span className={"title text-nowrap"}>{t(title)}</span>
                        </span>
                    </span>
                })}
            </div>
        </div>
        <div className={"d-flex h-100 flex-grow-1 border-top position-relative overflow-hidden insetShadow"}>
            <div className="w100 oa" style={{backgroundColor: "#eff1fd"}}>
            {''&&ECtns.map((ECtn, idx) =>{
                const View = CpECtnViews[ECtn.type];
                return <View key={idx} {...{...props, idx, ECtn}} />
            })}   
            {''&&preJS(ECtns, 3)}
            {''&&preJS(props.exers, 3)}
            <DoEx {...pvProps} />
            </div>
        </div>
        {newSrc? <LayerExNew {...{goPopExer, en:showEn, newSrc, setNewSrc, isCopy:1, subjMetas }} />: '' }
    </>
});
export default TabExercise; 


