import React, { useEffect, useState } from 'react';
import preval from 'preval.macro';

import { apiCallLoad, apiCallLoad_ } from '../libs/awsFuncs';
import * as UI from '../libs/libUI'; 

import { ReduxBind } from '../saga/ReduxState';
import { gst_set} from '../saga/globalState.saga';

import AppRoute from './AppRoute';

//=== (CSS Expf, Mobile First!!!) ===
import '../css/epphone.css';
import '../css/eppadPortrait.css';
import '../css/eppadLandscape.css';
import '../css/eppc.css';
//=== (CSS AT, Mobile First!!!) ===
import '../css/atphone.css';
import '../css/at550.css';
import '../css/atpadPortrait.css';
import '../css/atpadLandscape.css';
import '../css/atpc.css';

import { _ATRoot, _WebRoot } from '../consts/ATConstReact';
import { setAWSLogin } from '../AppExPFUser/PageRedirect';
import { useUILang } from '../AppExPf/utils/useUILang';
import { Loader } from '../AppExPf/AppUtil';
import { getBrowserDevice } from '../libs/libDevice';
import { logMode } from '../saga/ReduxState';
//const mapState = (state) => { return { state };};
const stage = process.env.REACT_APP_CFG;

const AppExPf = ReduxBind(props => {
  const dispatch = props.dispatch;
  const init = props._saga?.gst?.init; 
  const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();

  useEffect(() => {
    const initLang = window.localStorage.getItem('Lang');
    setUILang(initLang); //dispatch(lang_set(window.localStorage.getItem('Lang')));
  }, []);
  
  useEffect( () => {
    if(!init){
      dispatch( gst_set('device', getBrowserDevice()));
      //dispatch( gst_set('init', 0));
        apiCallLoad_( dispatch, '/init', onInit, {});
    }
    //MD.loadMathDisplay();
  }, [init]);

  const onInit = (res, err) => {
    if (logMode()) console.log('onInit', res);
    if(err){
      //RDX.SetMajorError(dispatch, {...err, message:err.message});
    }
    if(res){
      setAWSLogin(dispatch, res, err);
      dispatch( gst_set('init', 1));
    }
  };

  //lj({AppExPfSaga:props._saga.gst.alerts});
  if(!init)
    return <PageInit/>;

  const loading = props._saga?.loading?.count;
  const alert = props._saga.gst?.alerts?.[0];
  //{(process.env.REACT_APP_CFG === 'local1') && <DebugDiv />}
  return (<>
    <AppRoute />
    {loading?UI.ScreenMaskLoader('96px', {backgroundColor:'rgba(255,255,255,0.7)',zIndex:1090}):false}
    {alert? alert: false}
  </>);
});
export default AppExPf;

const PageInit = (props) => {
    //<h2 id="h2">Initial Loading...</h2>
    return (<div className="PageInit hvh p8">
        <center className="h100">
        <div className="h100 w0 vam"></div>
        <div className="w vam">
          {Loader('128px')}
          <div>initializing...</div>
        </div>
        </center>
    </div>);
};
const DebugDiv = (props => {
  const [debugVis, setDebugVis] = useState();
  return <div className="ATBody">
    <div style={{textAlign:'right'}}>
      {UI.Button0((debugVis?'hide':'show')+' Debug', e => setDebugVis(debugVis?0:1), 'btnDebug', '', {})}
    </div>
    {debugVis?<>
      <h1>AppRoute (Debug)</h1>
      <div><pre>REACT_BUILD: {preval`module.exports = new Date().toLocaleString();`}</pre></div>
      <div>WEBROOT: '{_WebRoot}'</div>
      <div>ATBROOT: '{_ATRoot}'</div>
      <div>REACT_APP_CFG: '{stage}'</div>
      <pre>
        saga.auth:
        {JSON.stringify(props?._saga?.auth, undefined, 2)}
      </pre>
      <div><pre>{JSON.stringify(props.state, undefined, 3)}</pre></div>
    </>:''}
  </div>;
});