import React, { useEffect, useState, useMemo, useRef } from 'react';
import CpQtnTimer from '../components/CpQtnTimer';
import { _ExCtType_CK, _ExCtType_Img, _ExCtType_Lnk, _ExCtType_Mp3, _ExCtType_Qtn, 
    _ExCtType_Txt, _ExCtType_Video } from '../../consts/ATValidateEcnts';
//import * as UI from '../../libs/libUI';
import { svgIcon2, iconButton } from '../components/CpATIcoBtn';
import { useUILang } from '../utils/useUILang';
import { debugMode } from '../../saga/ReduxState';
import CpIco from '../../AppExPFUser/_components/CpIco';
import { IconList } from '../../consts/ATIconListToUsePoc';
import CpQuestionSelector from '../components/CpQuestionSelector';
import CpActionButtonComponent from '../../AppExPFUser/_components/CpActionButtonComponent';
import { Nav,Navbar,Container,Col,Stack,Offcanvas } from 'react-bootstrap';
//import { ALIGN } from '../../AppExPFUser/_components/CpExPFConst';
//import CpPopoverComponent from '../components/CpPopoverComponent';

const ballIcon = {
    [_ExCtType_Qtn]:'labelDiagram/add',
    [_ExCtType_Txt]:'contentType/box',
    [_ExCtType_Img]:'contentType/image',
    [_ExCtType_CK]:'contentType/text',
    [_ExCtType_Video]:'contentType/video',
    [_ExCtType_Mp3]:'contentType/audio',
    [_ExCtType_Lnk]:'contentType/link'
};
const ballIcon2 = {
    [_ExCtType_Qtn]:IconList.labelDiagram.add,
    [_ExCtType_Txt]:IconList.contentType.box,
    [_ExCtType_Img]:IconList.contentType.image,
    [_ExCtType_CK]:IconList.contentType.text,
    [_ExCtType_Video]:IconList.contentType.video,
    [_ExCtType_Mp3]:IconList.contentType.audio,
    [_ExCtType_Lnk]:IconList.contentType.link
};

const CpATQtnHeader = (props) => {
    const {goBack, gotoQ, doSubmit, doCorr, preview, idx, ddList=[], caption, PVMode, showResult, showCorr=0, modified=0, saved=0, vis=0, setVis, answerVis,
        showScore, getScore, hasTimeLimit=0, parentTime=0, timeUsed=0, remainTime=0, pause=0, titleStr, endStr, isThr, noSave=0, debugInfo, hideHeader=0,
        isReme } = props;
    const [ t ] = useUILang();
    const cap = caption?caption:((ddList?.length>0)?(ddList[idx]?.caption):t('question-selector'));
    //const [vis, setVis] = useState(0);
    //const [offset, setOffset] = useState(0);
    //const popRef = useRef(null);
    const hRef = useRef(null);
    //console.log("header showCorr", showCorr)
/*
    useEffect(()=>{
        if (hRef.current) {
            const pp = document.getElementById('root');
            if (pp) {
                pp.insertBefore(hRef.current, pp.children[0]);
            };
        };
        return () => {
            const moh = document.getElementById('moveOverHeader');
            if (moh) { moh.remove(); }
        };
    },[]);
*/

    useEffect(()=>{
        
        //if (PVMode===1 && idx >= 0) gotoQ(null, idx);
    },[idx]);

    useEffect(()=>{
        if (hRef.current) {
            if (hideHeader) setVis(0);
            hRef.current.style.display = hideHeader?'none':'flex';
        };
    },[hideHeader]);
    /*
    useEffect(() => {
        const expfhead = document.getElementById("ATQtnHeader");
        //const nav = document.querySelector("#navbar");
        //const progressbar = document.querySelector("#progressbar");
        const expfHeight = expfhead?.getBoundingClientRect().height || 0;
        //const navHeight = nav?.getBoundingClientRect().height || 0;
        //const progressbarHeight = progressbar?.getBoundingClientRect().height || 0;
        setOffset(expfHeight);
        //setOffset(navHeight + progressbarHeight + expfHeight);
    }, [popRef]);
    */

    const showTimer = (!showResult) && (!showCorr) && (!pause) && (!isThr) && (!noSave);
    const centerTxt = (preview||showResult||showCorr)?((showResult||showCorr)?titleStr:t('preview')):'';
    const endTxt = (endStr||showResult||showCorr)?endStr:'';
    const noSaveBtn = showResult || showCorr || noSave;
    
    const exStyle = debugMode()?{height:'auto'}:{};
    
    const view = 'check-answer';
    // question.variant 
    /* mcq-dim mcq-active mcq-correct mcq-incorrect mcq-attempted
    icon={<CpIco src={IconList.general.filterAdvance}/>
    */
    
    const selectorPanel = useMemo(() => {
            return <div id="question-selector-container" className={view}>
                <Stack direction={"horizontal"} gap={3}
                    className={"flex-wrap justify-content-center justify-content-sm-start "}>
                    {ddList && ddList.map((ele, ii)=>
                        <CpActionButtonComponent key={ii} 
                            variant={`${ele?.variant} ${(!PVMode && ii===idx) ? "current" : ""} hover-dark-preview-primary`}
                            className={`number-indicator`}
                            title={ele.type===_ExCtType_Qtn?(ele.caption.replace('Q','')):''} 
                            icon={<CpIco src={ele.type!==_ExCtType_Qtn?ballIcon2[ele.type]:''}/>}
                            onClick={ (e) => gotoQ(e, ii) } />
                    )}
                </Stack>
            </div>
        }, [ddList, idx]);

    // showScore for polling use
    const showNew = 1;
    //const _showScore = ((endTxt !== undefined) || getScore >=0) && ((showResult || answerVis) && (preview || (!isThr)));
    //console.log({endTxt, getScore, showResult, answerVis, preview, isThr, _showScore});
    if ( showNew ) return <><div ref={hRef} id='moveOverHeader' className='moveOverHeader flexRowCenter' 
        style={{zIndex:1060, position:'fixed', top:0, left:0}}>
        <Navbar className="w100" id={"ATQtnHeader"} bg="preview-primary" expand="lg" sticky="top">
        <Container fluid>
            <Col className={"overflow-hidden"}>
                <Nav className={"d-flex flex-row justify-content-start"}>
                    <div className='flexRowBetween' style={{overflow:'hidden'}}>
                        <div className='flexRowStartFit f18 gap-2'>
                            <CpActionButtonComponent variant={"hover-dark-preview-primary"}
                                icon={<CpIco src={IconList.general.exit}/>} onClick={goBack}/>
                            <CpQuestionSelector title={cap} progress={1} setProgress={()=>{}} container={hRef}
                                selectorPanel={selectorPanel} view={view} vis={vis && (ddList.length>0)} setVis={setVis}/>
                        </div>
                        {(preview || showResult || showCorr)? <div className='f18' style={{alignSelf:'center',color:"white"}}>{centerTxt}</div>:''}
                        <div className='flexRowEndFit f18 gap-2'>
                            {(false && showResult && showScore && endTxt && !isThr)?<div className='ATQtnSingleMarks f18'>{endTxt}</div>:''}
                            {showTimer?<CpQtnTimer startTime={parentTime} timeUsed={timeUsed} remainTime={remainTime} hasTimeLimit={hasTimeLimit} />:''}
                            {((endTxt !== undefined) || getScore >=0) && ((showResult || showCorr || answerVis) && (preview || (!isThr)))? <CpActionButtonComponent variant={"white"}
                                className={"text-preview-primary bg-white pe-none"} 
                                title={`${t('score')}${isReme?'%':''}: ${(endTxt!==undefined)?endTxt:getScore}`} truncate={false}/>:''}
                            {modified?<span className={"d-flex fs-4 align-items-center pe-2"}><CpIco src={IconList.general.loading}/></span>:''}
                            {saved?<span className={"d-flex fs-4 align-items-center pe-2"} style={{color:'white'}}><CpIco src={IconList.general.saved}/></span>:''}
                            {(!noSaveBtn || isThr)?<CpActionButtonComponent variant={"preview-secondary"} 
                                title={(preview || !isThr)?t('submit'):t('save')} truncate={false} onClick={doSubmit} />:''}
                            {debugMode()?<CpActionButtonComponent variant={"preview-secondary text-nowrap"} 
                                title={'test Curr'} truncate={false} onClick={doCorr} />:''}                                
                        </div>
                    </div>
                </Nav>
            </Col>
            </Container>
        </Navbar>
        </div>{(debugMode() && debugInfo)?debugInfo():''}</>
/*
                            {(showResult && showScore && !isThr)? <CpActionButtonComponent variant={"white"}
                                className={"text-preview-primary bg-white pe-none"} 
                                title={`${t('score')}: ${getScore}`} truncate={false}/>:''}
*/
    return <div id="ATQtnHeader" className="ATQtnHeader" style={exStyle}>
        <div className='flexRowBetween' style={{overflow:'hidden'}}>
            <div className='flexRowStartFit f20'>
                <CpActionButtonComponent variant={"hover-dark-preview-primary"}
                    icon={<CpIco src={IconList.general.exit}/>} onClick={goBack}/>
                <div className='clickable usel' onClick={()=>{setVis(!vis)}}>{cap}</div>
                {iconButton('',vis?'player/arrowdown':'player/arrowdown','white','transparent',
                    (e)=>setVis(!vis),true,{transform:'scale(1.2)'})}                 
            </div>

            {(preview || showResult)? <div className='f18' style={{alignSelf:'center',color:"white"}}>{centerTxt}</div>:''}

            <div className='flexRowEndFit f18 gap-2'>
                {(false && showResult && endTxt && !isThr)?<div className='ATQtnSingleMarks f18'>{endTxt}</div>:''}
                {showTimer?<CpQtnTimer startTime={parentTime} timeUsed={timeUsed} remainTime={remainTime} />:''}
                {(showResult && showScore && !isThr)? <CpActionButtonComponent variant={"white"}
                    className={"text-preview-primary bg-white pe-none"} 
                    title={`${t('score')}: ${endTxt}`} truncate={false}/>:''}
                {modified?<span className={"d-flex fs-4 align-items-center pe-2"}><CpIco src={IconList.general.loading}/></span>:''}
                {saved?<span className={"d-flex fs-4 align-items-center pe-2"}><CpIco src={IconList.general.saved}/></span>:''}
                {(!noSaveBtn || isThr)?<CpActionButtonComponent variant={"preview-secondary"} 
                    title={(preview || !isThr)?t('submit'):t('save')} truncate={false} onClick={doSubmit} />:''}                
            </div>
        </div>
        {(false && debugMode() && debugInfo)?debugInfo():''}
        {(vis && ddList)?<div className='ATQtnHeaderSlide text-truncate gap-3'>
            {ddList.map((ele, ii)=>{
                return <div key={ii} className={'ATQtnHeaderBtn f13 ' + ((!PVMode && ii===idx)?'ATQtnHeaderBtnSel':'')}
                    onClick={(e)=>gotoQ(e, ii)} style={{backgroundColor:ele.color}}>
                    {ele.type===_ExCtType_Qtn?(ele.caption.replace('Q','')):
                    svgIcon2(ballIcon[ele.type],'white',{})}
                </div>
            })}
        </div>:''}        
    </div>    
};
// view === "check-answer" "doing" "manualmark"
export default CpATQtnHeader;

    /* new one
    return <Navbar className="w100" id={"ATQtnHeader"} bg="preview-primary" expand="lg" sticky="top">
    <Container fluid>
        <Col className={"overflow-hidden"}>
            <Nav className={"d-flex flex-row justify-content-start"}>
                <div className='flexRowBetween' style={{overflow:'hidden'}}>
                    <div className='flexRowStartFit f20'>
                        <CpActionButtonComponent variant={"hover-dark-preview-primary"}
                            icon={<CpIco src={IconList.general.exit}/>} onClick={goBack}/>
                        <CpQuestionSelector title={cap} progress={1} setProgress={()=>{}} 
                            selectorPanel={selectorPanel} view={view} vis={vis} setVis={setVis}/>
                    </div>
                    {(preview || showResult)? <div className='f18' style={{alignSelf:'center',color:"white"}}>{centerTxt}</div>:''}
                    <div className='flexRowEndFit f18' gap={2}>
                        {(showResult && endTxt && !isThr)?<div className='ATQtnSingleMarks f18'>{endTxt}</div>:''}
                        {showTimer?<div><CpQtnTimer startTime={parentTime} timeUsed={timeUsed}/></div>:''}
                        {(showResult && showScore && !isThr)? <CpActionButtonComponent variant={"white"}
                            className={"text-preview-primary bg-white pe-none"} 
                            title={`${t('score')}: ${getScore}`} truncate={false}/>:''}
                        {modified?<span className={"d-flex fs-4 align-items-center pe-2"}><CpIco src={IconList.general.loading}/></span>:''}
                        {saved?<span className={"d-flex fs-4 align-items-center pe-2"}><CpIco src={IconList.general.saved}/></span>:''}
                        {(!noSaveBtn || isThr)?<CpActionButtonComponent variant={"preview-secondary"} 
                            title={(preview || !isThr)?t('submit'):t('save')} truncate={false} onClick={doSubmit} />:''}
                    </div>
                </div>
                {(debugMode() && debugInfo)?debugInfo():''}
            </Nav>
        </Col>
        </Container>
    </Navbar>
    */

/*
    return <div id="ATQtnHeader" className="ATQtnHeader" style={exStyle}>
        <div className='flexRowBetween' style={{overflow:'hidden'}}>
            <div className='flexRowStartFit f20'>
                {iconButton('','general/previous','white','transparent',goBack,true,{transform:'scale(1.5)'})}
                <div className='clickable usel' onClick={()=>{setVis(!vis)}}>{cap}</div>
                {iconButton('',vis?'player/arrowup':'player/arrowdown','white','transparent',
                    (e)=>setVis(!vis),true,{transform:'scale(1.8)'})}                 
            </div>

            {(preview || showResult)? <div className='f18' style={{alignSelf:'center',color:"white"}}>{centerTxt}</div>:''}

            <div className='flexRowEndFit f18'>
                {(showResult && endTxt && !isThr)?<div className='ATQtnSingleMarks f18'>{endTxt}</div>:''}
                {showTimer?<div><CpQtnTimer startTime={parentTime} timeUsed={timeUsed}/></div>:''}
                {(showResult && showScore && !isThr)?<div className='ATQtnSingleMarks'>{t('score')+': ' + getScore}</div>:''}
                {modified?<span className={"d-flex fs-4 align-items-center pe-2"}><CpIco src={IconList.general.loading}/></span>:''}
                {saved?<span className={"d-flex fs-4 align-items-center pe-2"}><CpIco src={IconList.general.saved}/></span>:''}
                {(!noSaveBtn || isThr)?UI.colorButton('btnSubmit',(preview || !isThr)?t('submit'):t('save'),doSubmit,'#ec8008','80px',{height:'40px'}):''}
            </div>
        </div>
        {(debugMode() && debugInfo)?debugInfo():''}
        {vis?<div className='ATQtnHeaderSlide text-truncate gap-3'>
            {ddList && ddList.map((ele, ii)=>{
                
                return <div key={ii} className={'ATQtnHeaderBtn f13 ' + ((!PVMode && ii===idx)?'ATQtnHeaderBtnSel':'')}
                    onClick={(e)=>gotoQ(e, ii)} style={{backgroundColor:ele.color}}>
                    {ele.type===_ExCtType_Qtn?(++QIndex):
                    svgIcon2(ballIcon[ele.type],'white',{})}
                </div>
            })}
        </div>:''}        
    </div>      


    <div className='flexRowStart'><Navbar id={"navbar"} bg="preview-primary" expand="lg" sticky="top">
        <Container fluid>
            <Col className={"overflow-hidden"}>
                <Nav className={"d-flex flex-row justify-content-start"}>
                    <CpActionButtonComponent
                        variant={"hover-dark-preview-primary"}
                        icon={<CpIco src={IconList.general.exit}/>}
                        onClick={() => {}}/>
                    <Nav.Item>
                        <CpQuestionSelector title={'' || `Q${1}`}
                        progress={1} setProgress={()=>{}} view={view}/> 
                    </Nav.Item>
                </Nav>
            </Col>

            <Col className={"overflow-hidden"}>
                <Nav className={"justify-content-end"}>
                    <Stack gap={2} direction={"horizontal"} className={"justify-content-end"}>
                        {view === "check-answer" && <CpActionButtonComponent variant={"white"}
                            className={"text-preview-primary bg-white pe-none"}
                            title={`${t('score')}: 10`}
                            truncate={false}/>}
                        {view === "doing" &&
                            <><CpActionButtonComponent variant={""}
                                className={"text-preview-primary bg-white pe-none"}
                                title={"00:52:18"} truncate={false}/>
                            <CpActionButtonComponent variant={"preview-secondary"}
                                title={t("submit")} truncate={false}/></>
                        }
                        {view === "manualmark" &&
                            <><CpActionButtonComponent variant={"preview-secondary"} title={t("save")} truncate={false}/></>
                        }
                    </Stack>
                </Nav>
            </Col>
        </Container>
    </Navbar></div>

<>
        <CpPopoverComponent ref={popRef} content={selectorPanel} vis={true} setVis={setVis}
            fixedPosition={true} placement={"top"} scroll={true} backdrop={false}
            style={{marginTop: offset, zIndex: 1000}}>
        </CpPopoverComponent>
        <div id="ATQtnHeader" className="ATQtnHeader" style={exStyle}>
            <div className='flexRowBetween' style={{overflow:'hidden'}}>
                <div className='flexRowStartFit f20'>
                    <div style={{zIndex:1050, width:'200px'}}>hello</div>
                    <CpActionButtonComponent variant={"hover-dark-preview-primary"}
                            icon={<CpIco src={IconList.general.exit}/>} onClick={goBack}/>                
                    <CpActionButtonComponent variant="hover-dark-preview-primary" title={cap} 
                        icon={<div className={"fs-8"}><CpIco src={IconList.general.dropdown}/></div>}
                        iconAlign={ALIGN.END} className={"fs-5 semi-bold"} onClick={( )=> setVis(prev => !prev) } />
                </div>
                {(preview || showResult)? <div className='f18' style={{alignSelf:'center',color:"white"}}>{centerTxt}</div>:''}
                <div className='flexRowEndFit f18' gap={2}>
                    {(showResult && endTxt && !isThr)?<div className='ATQtnSingleMarks f18'>{endTxt}</div>:''}
                    {showTimer?<div><CpQtnTimer startTime={parentTime} timeUsed={timeUsed}/></div>:''}
                    {(showResult && showScore && !isThr)? <CpActionButtonComponent variant={"white"}
                        className={"text-preview-primary bg-white pe-none"} 
                        title={`${t('score')}: ${getScore}`} truncate={false}/>:''}
                    {modified?<span className={"d-flex fs-4 align-items-center pe-2"}><CpIco src={IconList.general.loading}/></span>:''}
                    {saved?<span className={"d-flex fs-4 align-items-center pe-2"}><CpIco src={IconList.general.saved}/></span>:''}
                    {(!noSaveBtn || isThr)?<CpActionButtonComponent variant={"preview-secondary"} 
                        title={(preview || !isThr)?t('submit'):t('save')} truncate={false} onClick={doSubmit} />:''}
                </div>
            </div>
            {(debugMode() && debugInfo)?debugInfo():''}
        </div>  
    </>    

{view === "doing" && <ProgressbarComponent variant={"preview-secondary"} percentage={progress}/>}

        {vis?<div className='ATQtnHeaderSlide text-truncate gap-3'>
            {ddList && ddList.map((ele, ii)=>{
                
                return <div key={ii} className={'ATQtnHeaderBtn f13 ' + ((!PVMode && ii===idx)?'ATQtnHeaderBtnSel':'')}
                    onClick={(e)=>gotoQ(e, ii)} style={{backgroundColor:ele.color}}>
                    {ele.type===_ExCtType_Qtn?(++QIndex):
                    svgIcon2(ballIcon[ele.type],'white',{})}
                </div>
            })}
        </div>:''}

        <Offcanvas show={vis} onHide={()=>setVis(0)} scroll={true} placement = "top">
            <Offcanvas.Header>
                <Offcanvas.Title>Offcanvas</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <div style={{marginTop:'100px'}}>
            Some text as placeholder. In real life you can have the elements you
            have chosen. Like, text, images, lists, etc.
            </div>
            </Offcanvas.Body>
        </Offcanvas>        

    <div id="ATQtnHeader" className="ATQtnHeader" style={exStyle}>
        <div className='flexRowBetween' style={{overflow:'hidden'}}>
            <div className='flexRowStartFit f20'>
                {iconButton('','general/previous','white','transparent',goBack,true,{transform:'scale(1.5)'})}
                <div className='clickable usel' onClick={()=>{setVis(!vis)}}>{cap}</div>
                {iconButton('',vis?'player/arrowup':'player/arrowdown','white','transparent',
                    (e)=>setVis(!vis),true,{transform:'scale(1.8)'})}
                <CpQuestionSelector title={'' || `Q${1 / 10}`}
                    progress={1} setProgress={()=>{}} view={"check-answer"}/>                    
            </div>

            {(preview || showResult)? <div className='f18' style={{alignSelf:'center',color:"white"}}>{centerTxt}</div>:''}

            <div className='flexRowEndFit f18'>
                {(showResult && endTxt && !isThr)?<div className='ATQtnSingleMarks f18'>{endTxt}</div>:''}
                {showTimer?<div><CpQtnTimer startTime={parentTime} timeUsed={timeUsed}/></div>:''}
                {(showResult && showScore && !isThr)?<div className='ATQtnSingleMarks'>{t('score')+': ' + getScore}</div>:''}
                {modified?<span className={"d-flex fs-4 align-items-center pe-2"}><CpIco src={IconList.general.loading}/></span>:''}
                {saved?<span className={"d-flex fs-4 align-items-center pe-2"}><CpIco src={IconList.general.saved}/></span>:''}
                {(!noSaveBtn || isThr)?UI.colorButton('btnSubmit',(preview || !isThr)?t('submit'):t('save'),doSubmit,'#ec8008','80px',{height:'40px'}):''}
            </div>
        </div>
        {(debugMode() && debugInfo)?debugInfo():''}
    </div>        
*/