import React, { useEffect, useMemo, useState } from "react";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { ReduxBind } from "../../saga/ReduxState";
import { _WST_SUBMIT } from "../../consts/ATConstsAssignment";
import { aryLen, objKeys, toAry } from "../../libs/libType";
import CardStudentAssignmentCard, { stuPassAsm } from "./CardStudentAssignmentCard";
import CpDropdown, { ent2DropdownItem } from "../_components/CpDropdown";
import { RemeLvALL, RemeLvPre, RemeLvPro, RemeLvSame } from "../../consts/ATValidateReme";
import { xapiPost } from "../EPLibrary/PageLibrary";


const RPId_pass = '*rPass';
const RPId_fail = '*rFail';
const RPId_all = '*rAll';

const TabStudentAssigmentReme = ReduxBind(props => {
    const [t, uiEn, lang, setLang, ut] = useUILang();
    const { remeAsms, asm, Exer, work, mark, myNexts } = props;
    const {dispatch, loadRemeAsmDo} = props;
    const myUId = props.userId();

    const asmId = asm?.assignId;
    useEffect(() => {xapiPost(dispatch, 'xENextReme', {asmId});}, [asmId]);

    const myRemes = useMemo(() => toAry(props.myRemes), [props.myRemes]);
    const myFailRemes = useMemo(() => toAry(props.myFailRemes), [props.myFailRemes]);
    const myPassRemes = useMemo(() => myRemes.filter(r => !myFailRemes.includes(r)), [myRemes, myFailRemes]);

    const clickAsm = loadRemeAsmDo;//clickRemePV;
    const clickAsmRpt = loadRemeAsmDo;//clickRemePV;
    const cardProps = {...props, clickAsm, clickAsmRpt, layout:'list'}; 

    const cntFail = aryLen(myFailRemes);
    const cntPass = aryLen(myRemes) - cntFail; 
    const rmPassDDI = useMemo(() => ent2DropdownItem([
        //[RPId_all, t('assignment-status-option-follow-up-all')],
        [RPId_all, t('assignment-filter-options-all')],
        cntFail && [RPId_fail, t('assignment-status-option-follow-up-uncompleted')],
        cntPass && [RPId_pass, t('assignment-status-option-follow-up-complete')],
    ].filter(o => o)), [cntFail, cntPass, uiEn]); //EX-1693
    const [_rmPass, setRmPass] = useState(RPId_all);
    const pks = objKeys(rmPassDDI);
    const rmPass = pks.includes(_rmPass)? _rmPass: RPId_all;

    const remeAllLvs = (rmPass === RPId_fail)? myFailRemes: (rmPass === RPId_pass)? myPassRemes: myRemes;

    const [remeSames, remePres, remePros] = useMemo(() => [RemeLvSame, RemeLvPre, RemeLvPro].map
        (lv => remeAllLvs.filter(r => toAry(r.remeLvStus?.[lv]).includes(myUId))), [remeAllLvs]);        

    const rmLvDDI = useMemo(() => ent2DropdownItem([
        [RemeLvALL, t('assignment-status-options-all')],
        aryLen(remeSames) && [RemeLvSame, t('assignment-status-option-follow-up-same')],
        aryLen(remePres) && [RemeLvPre, t('assignment-status-option-follow-up-pre')],
        aryLen(remePros) && [RemeLvPro, t('assignment-status-option-follow-up-pro')],
    ].filter(o => o)), [remeAllLvs, uiEn]); //EX-1693
    const [_rmLv, setRmLv] = useState(RemeLvALL);
    const lks = objKeys(rmLvDDI);
    const rmLv = lks.includes(_rmLv)? _rmLv: RemeLvALL;
    
    const asms = (rmLv === RemeLvSame)? remeSames:
        (rmLv === RemeLvPre)? remePres:
        (rmLv === RemeLvPro)? remePros:
        remeAllLvs;

    //useMemo(() => toAry(remeAsms).filter(r => {
    //    if((rmLv !== RemeLvALL) && (!toAry(r.remeLvs).includes(rmLv))) return 0;
    //    if (rmPass === RPId_all) return 1;
    //    const upass = stuPassAsm(r, r.mark); 
    //    return needPass? upass: !upass; 
    //}), [remeAsms, rmLv, rmPass]);
    
    //const paging = usePaging(asms.length, 50, 1);
    //<div className_={"TabStudentAssigmentReme table-data-container d-flex gap-2 flex-grow-1"}>
    //<div className={"d-flex flex-column gap-2 gap-md-3 w-100 overflow-auto px-3 pb-5 pb-md-3"}>
    //<hr className={"mt-2 mb-3 mx-3"}/>
    return <div className={"TabStudentAssigmentReme p-3"}>
        {/*<div className={"d-flex justify-content-between flex-wrap align-items-center mb-3"}>
            <h4 className={"m-0"}>{t("report-next-steps-exercises")}:</h4>
        </div>*/}
        <div className={"exercise-library-filter-container d-flex flex-column flex-md-row justify-content-md-between align-items-md-end gap-3 mt-0 mt-md-3 w-100"}>
            <span className={"flex-wrap gap-3 align-items-center"}>
                <span className={`d-flex flex-column flex-md-row gap-2 align-items-start align-items-md-center`}>
                    {/*ex-1693 <span className={"d-flex align-items-center semi-bold"}>{t("question-scope")}:</span>*/}
                    <span className={"d-flex align-items-center semi-bold"}>{t("status")}:</span>
                    <span className={`d-flex`} style={{width: "200px"}}>
                        <CpDropdown {...{id:'DDStuRmPass', className:"w", placeHolder:'', items:rmPassDDI, idx:rmPass, cb:setRmPass}} />
                    </span>
                </span>            
            </span>
            <span className={"d-flex gap-2 align-items-center justify-content-between"}>
                <span className={"gap-3 align-items-center"}>
                    <span className={`d-flex flex-column flex-md-row gap-2 align-items-start align-items-md-center`}>
                        <span className={"d-flex align-items-center semi-bold"}>{t("question-scope")}:</span>
                        <span className={`d-flex`} style={{width: "200px"}}>
                        <CpDropdown {...{id:'DDStuRmLv', className:"w", placeHolder:'', items:rmLvDDI, idx:rmLv, cb:setRmLv}} />
                        </span>
                    </span>            
                </span>
            </span>
        </div>
        <hr className={"mt-2 mb-3"} />
        <div className={"d-flex flex-column gap-2 gap-md-3 w-100 overflow-auto pb-5 pb-md-3"}>
            {asms.map((asm, idx) => <CardStudentAssignmentCard {...{...cardProps, key:idx, asm}} />)}
        </div>
    </div>
});
export default TabStudentAssigmentReme;

/*
    return <>
        <h4 className={"m-0"}>{t("report-next-steps-exercises")}:</h4>
<div className="d-flex flex-column flex-lg-row gap-2 align-items-start align-items-lg-center" style={{}} >
    <span >{t('status')}</span>
    <span className="" style={{width:'300px'}} >
        <CpDropdown {...{id:'DDStuRmPass', className:"w", placeHolder:'', items:rmPassDDI, idx:rmPass, cb:setRmPass}} />
    </span>
    <span >{t('question-scope')}</span>
    <span className="" style={{width:'300px'}} >
        <CpDropdown {...{id:'DDStuRmLv', className:"w", placeHolder:'', items:rmLvDDI, idx:rmLv, cb:setRmLv}} />
    </span>
</div>
        <hr className="mt-2 mb-3 mx-3" />
        {asms.map((asm, idx) => <CardStudentAssignmentCard {...{...cardProps, key:idx, asm}} />)}
    </>
*/