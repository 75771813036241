import React, { useEffect, useMemo, useState } from 'react';
//import Dropdown from '../../poc/screens/pages/included/Dropdown';

import { asyncApiCall_, asyncApiCallLoad_ } from '../../libs/awsFuncs';
import { isAry, isFunc, isObj, objEntries, objKeys, objVals, toAry, toInt, toObj, toStr } from '../../libs/libType';
import { Array2Object } from '../../libs/libArray';
import * as UI from '../../libs/libUI';
import { _WebRoot, _ATRoot, _LibraryRoot, _ExerciseRoot, _CollectionRoot, _pathEcol, _pathOupEx } from '../../consts/ATConstReact';

import { urlPush_Replace } from '../../saga/urlPush.saga';
import { ReduxBind } from '../../saga/ReduxState';

import { usePaging } from '../../AppExPf/utils/ATPaging';

import PageEXPFLayout from '../PageEXPFLayout';
import TabLibOup from './TabLibOup';

import { clickUrl, preJS } from '../../AppExPf/AppUtil';
import { useCtnLang, useUILang, validLang } from '../../AppExPf/utils/useUILang';

import CpHeaderTitle from '../_components/CpHeaderTitle';
import TabExercises from '../EPExercise/TabExercises';
import PageExerciseEdit from '../EPExercise/PageExerciseEdit';

import ScrollContainer from '../_components/CpScrollContainer';

import { IconList } from '../../consts/ATIconListToUsePoc';
import { useQtnCache } from '../../AppExPf/utils/useQtnCache';
import { useMediaCache } from '../../AppExPf/utils/useMediaCache';
import { LayerECollEdit } from '../EpCollect/LayerECollEdit';
import { LayerExlDel } from '../EPExercise/LayerExDel';
import CpExerciseTabletListFilterCTA from './CpExerciseTabletListFilterCTA';
import CpPagination from '../_components/CpPagination';
import { _EPSECT_ecol, _EPSECT_lib, _EPSECT_my, _EPSECT_oup, _EPSECT_school } from '../AppRouteEP';
import TabCollection from '../EpCollect/TabCollection';
import { useTagMSetCache } from '../../AppExPf/utils/useTagMSetCache';
import CpCreateExerciseCTA from '../_components/CpCreateExerciseCTA';
import CpStickyNavTab from '../_components/CpStickyNavTab';
import { fixeECnts } from '../../consts/ATValidateEcnts';
import { useExLikes } from '../EPExercise/CpExerciseCard';
import { subjMetaCombo } from '../EPAssign/TabAssignmentSettingClass';
import { gst_pushAlert, useStateGST } from '../../saga/globalState.saga';
import CpExerciseEditHeader from './CpExerciseEditHeader';
import { useCaches } from '../../AppExPf/utils/useCaches';
import { alertHolder, maskAlert, popAlert } from '../../AppExPf/components/CpPopup';
import { Button, Card } from 'react-bootstrap';
import { sanitize } from 'dompurify';
import CpSharedModal from '../_components/CpSharedModal';
import SharedModalHeader from '../_components/CpSharedModalHeader';
import { CpSettingModalSection, CpSettingModalTabContainer } from '../_components/CpSettingModalTabCps';

const PageLibrary = ReduxBind(props => {
    const {dispatch} = props;
    const role = props.user()?.role;// 'teacher'; //searchParams.get("role") || "teacher"

    const [ cache2d, useGetCache ] = useCaches(props);
    const [ tagMSets, useTagMSets ] = useTagMSetCache(dispatch); 
    const [qtns, useCacheQtns, ] = useQtnCache(dispatch, 0);
    const [ mediaDLs, getMediaDLs, LocalMediaDL, addLocalMedias ] = useMediaCache(props, dispatch, 0);

    const params = toObj(props.match?.params);
    const {EColId, ESetId, EId, cLang} = params;
    
    const [t, uiEn, UILang, setUILang, ut] = useUILang();
    const initL = validLang(cLang||UILang);
    const [showEn, ct, filpCtnL, ctnL, setCtnL] = useCtnLang(initL);
    
    useEffect(() => { setCtnL(initL); }, [initL]);

    const sect =  params.sect || _EPSECT_oup;  
    const sectMyEx = (sect === _EPSECT_my) 
    const sectECol = (sect === _EPSECT_ecol);
    const sectOup = (sect === _EPSECT_oup) 
    const sectSchool = (sect === _EPSECT_school); 
    const sectExer = sectMyEx || sectECol;
    const sectLib = sectOup || sectSchool; 
    const setTab = t => (
        (t === _EPSECT_oup)? dispatch(urlPush_Replace(_pathOupEx(ctnL))):
        (t === _EPSECT_school)? dispatch(urlPush_Replace(_LibraryRoot+'/school')):
        (t === _EPSECT_my)? dispatch(urlPush_Replace(_ExerciseRoot)):
        (t === _EPSECT_ecol)? dispatch(urlPush_Replace(_CollectionRoot)):
        ''
    );

    const [paraDel, setParaDel] = useState(); //del Layer
    const [paraNew, setParaNew] = useState(); //create Layer

    const [ECols, setECols] = useState([]);
    const [ECol, setECol] = useState();

    const [ESets, setESets] = useState();
    const _setESets = esets => setESets(Array2Object(esets, 'ESetId')); 
    
    const _oupESets = useGetCache('oupESets', '*');

    useEffect(() => { _setESets(toAry(_oupESets).map(clearESet)); }, [_oupESets]);

    const [eset, setESet] = useState();
    const [ESTagMSets, setESTagMSets] = useState();
    const [ESharerIds, setESharerIds] = useState();
    
    const [EIds, setEIds] = useState([]);
    const [exers, setExers] = useState({});
    
    const ttlRow = toAry(EIds).length;
    const paging = usePaging(ttlRow, 50, 1);
    const { page, rpp, totalRow, totalPage, res1, res2, setPaging } = paging;
    useEffect(() => { setPaging(paging) }, [ttlRow] );

    const _subjMetas = useGetCache('subjMetas', '*');
    const subjMetas = useMemo(() => Object.fromEntries(toAry(_subjMetas).map(s => [s.metaSetId+','+s.metaId, s])), [_subjMetas]);  
    const jDDI = useMemo( () => ({ '*':{t:t('all')}, ...subjMetaCombo(subjMetas, ut) })  , [subjMetas, uiEn]);

    const [_jFilter, setJFilter] = useStateGST(props, 'TabLibOupSubj');
    const jFilter = _jFilter || objKeys(jDDI)[0];
    
    const [exFilters, _setExFilters] = useState();
    const setExFilters = (efs, caller) => {
        _setExFilters(efs);
        _reload(efs, caller);
    };

    const { eLikes, setELikes, loadELikes} = useExLikes(dispatch);
    
    const {popExer, setPopExer:_setPopExer, goPopExer, exModified, setExModified} = usePopExer(props);
    const setPopExer = (v) => { setExModified(v? 1: 0); _setPopExer(v); };
    const clickUnpopExer = e => { UI.stopEvent(e); _setPopExer(0); };
    
    const loadECols = async (para) => {
        const [res, err] = await asyncApiCallLoad_(dispatch, '/getECols', para);
        setECols(toAry(res?.ECols));
    };

    const loadEColEs = async () => {
        if(!EColId) return;
        const [res, err] = await asyncApiCallLoad_(dispatch, '/getEColEs', {EColId});
        const { ECol, EIds, } = toObj(res);
        setECol(ECol);
        if(isAry(EIds)) setEIds(EIds);
    };

    const pageEIds = useMemo(() => toAry(EIds).slice(res1-1, res2), [EIds, res1, res2]);
    const reloadPage = async (caller) => {
        const [res, err] = await asyncApiCallLoad_(dispatch, '/getExers', { EIds: pageEIds, caller });
        const exers = res?.exers;
        if(isAry(exers)) setExers(Array2Object(fixExs(exers), 'EId'));
    };
    useEffect(() => { reloadPage() }, [pageEIds]);

    const findEIds = async (efs, para, caller) => {
        const [res, err] = await asyncApiCallLoad_(dispatch, '/findExers', {exFilters:{...efs, showEn, jFilter}, ...para, caller})
        const {eset, ESTagMSets, EIds, ECols, ESharerIds} = toObj(res); 
        setESet(eset);
        if(isAry(ESharerIds)) setESharerIds(ESharerIds);
        if(isAry(EIds)) setEIds(EIds);
        if(isAry(ECols)) setECols(ECols);
        if(ESTagMSets) setESTagMSets((ESTagMSets));
    };

    const _reload = (efs, caller) => {
        if(sectSchool){ 
            findEIds(efs, {list:'school'}, caller);
        } else if(sectMyEx) {
            findEIds(efs, {list:'my'}, caller);
        }else if(sectOup){
            ESetId && findEIds(efs, {ESetId}, caller); 
        }else if(sectECol){
            EColId? loadEColEs(): loadECols({...efs, full:1, caller});
        }   
    };
    const reload = (caller) => _reload(exFilters, caller);

    useEffect(() => { if(sectMyEx && (!popExer)) reload('exitPopExer'); }, [popExer]);
    useEffect(() => { popExer || sectOup || reload('jFilter'); }, [jFilter]);
    
    //useEffect(() => { popExer || reload('initPage'); }, []);
    useEffect(() => { 
        if(!popExer) setExFilters({}, 'ELXSect'); }, [sect, ESetId, EColId] );
    useEffect(() => { if(!popExer) setJFilter('*') }, [sect] );

    const [logE, setLogE] = useState();
    useEffect(() => { setLogE(popExer? [jFilter, EColId, ESetId, EId, ctnL, sect].join('|'): ''); }, [popExer]);
    useEffect(() => { 
        if(logE && popExer && (!popExer.isNewE)) xapiPost(dispatch, 'xOpenE', {jFilter, EColId, ESetId, EId, ctnL, sect, }); 
    }, [logE]);

    useEffect(() => {  loadELikes(); (sectECol || loadECols({ECCount:1})); }, [] );

    const tabProps = {sect, ESetId, EId, subjMetas, tagMSets, useTagMSets, ESets, setESets, eset, setESet, 
        ESTagMSets, ESharerIds, pageEIds, exers, setExers, exModified, setExModified,
        jItems: jDDI, jFilter, setJFilter, exFilters, setExFilters,
        popExer, goPopExer, eLikes, setELikes, setParaDel, setParaNew, 
        qtns, useCacheQtns, mediaDLs, getMediaDLs, t, cLang, ctnL, ct, reload, setECols, }; 

    const title = popExer?
            <CpExerciseEditHeader {...{clickBack:clickUnpopExer, ct, ...popExer, setPopExer}}/>  //EXERCISE_EDIT_PAGE
        :(ESetId && eset)
            ?<CpHeaderTitle animation={true} iconPath={IconList.general.previous} title={ct(eset.ESNameEn, eset.ESNameCt)} 
                onClick={clickUrl(dispatch, _pathOupEx(ctnL, ''))} />//LIBRARY_SEARCH_PAGE
        :(EColId && ECol)
            ?<CpHeaderTitle animation={true} iconPath={IconList.general.previous} title={toStr(ECol.ECName)} 
                onClick={clickUrl(dispatch, _pathEcol(''))} />//LIBRARY_SEARCH_PAGE
        :sectExer
            ?<CpHeaderTitle animation={true} iconPath={IconList.menu.myExercises} title={t("sidemenu-my-exercises")} /> 
            :<CpHeaderTitle animation={true} iconPath={IconList.menu.library} title={t("sidemenu-library")} /> 

    const actionBar =  popExer? '': <>
            {(sectOup && ESetId)? <CpExerciseTabletListFilterCTA {...{...tabProps, defSort:1}} />: ''}
            <CpCreateExerciseCTA {...tabProps} />
        </>;


    const drawTabBody = (hasTabs) => {
        if(popExer)return <PageExerciseEdit {...{...tabProps, ...popExer, setPopExer, goPopExer, back:()=>setPopExer(0) }} />;
        if(sectOup) return <TabLibOup {...{...tabProps}} />;
        if(sectECol && !EColId) return <TabCollection {...{...tabProps, ECols}} />;

        
        const baseUrl = sectMyEx? _ExerciseRoot:
            sectSchool? _LibraryRoot + '/school':
            sectECol? _pathEcol(EColId):''; 
            const showFilter = sectSchool||sectMyEx;
            //const tabTitle = sectMyEx?t('created-by-me'): sectSchool? t('shared-exercises'): '';
            const tabTitle = sectMyEx? '' : sectSchool? t('shared-exercises'): '';
            return <>{hasTabs?'':<div className={"px-3 py-2"}></div>}
                <TabExercises {...{...tabProps, tabTitle, baseUrl, showFilter}} />
            </>;
        //:(sectSchool)? 'todo <TabLibSchool {...{...tabProps}} reloadId={reloadId} setReloadId={setReloadId}/>'
        //:(sectMyEx)? 'sectMyEx' 
        //:''
    };

    const drawTabs = () => {
        if(popExer) return '';
        if(sectSchool || (sectLib && !ESetId)) return [
            {id: _EPSECT_oup, iconPath: IconList.brand.oupTab, iconOnly: true, className: "tab-oupc"}, //TAB.LIBRARY_OUPC
            {id: _EPSECT_school, title: t("my-school")} //TAB.LIBRARY_MY_SCHOOL
        ];
        if(sectMyEx || (sectExer && (!EColId))) return  [
            {id: _EPSECT_my, title: "my-exercises" },
            {id: _EPSECT_ecol, title: "saved-collections", iconPath: IconList.general.collection, count: toAry(ECols).length,  },
        ];
        return '';
    };
    const tabItmes = drawTabs(); 
    const hasTabs = tabItmes?1:0;
    
    const subroot = sectExer? _ExerciseRoot :sectLib? _LibraryRoot: '';
    const noFooter = popExer || (sectOup && !ESetId);
    const isEdit = (popExer?.fields) ?1:0;

    return <PageEXPFLayout sideIdx={subroot}
        menuTitle={title} 
        menuActionBar={actionBar} 
        header=''//'headerheaderheader'
        subBody=''//'subBodysubBody'
        footer={noFooter ? '': <CpPagination {...{paging}} />}>
        <div className={"d-flex flex-column h-100 overflow-hidden " + (noFooter?'':' pageLibrary') + (isEdit?' pageExerEdit':'')}>
            <ScrollContainer className={"vh-100 d-flex flex-column w-100 overflow-auto overflow-y-md-hidden"}>
                {hasTabs?<div className={"px-3 py-2"}>
                     <CpStickyNavTab items={tabItmes} active={sect} handleActive={setTab} className={"exercise-library-tab-theme"}/>
                </div>:''}
                {drawTabBody(hasTabs)}
            </ScrollContainer>
        </div>
        <LayerExlDel {...{...tabProps, para:paraDel, setPara:setParaDel}} />
        <LayerECollEdit {...{...tabProps, para:paraNew, setPara:setParaNew }} />
    </PageEXPFLayout>;
});
export default PageLibrary;

const clearESet = eset => {
    if(!eset) return;
    const {ESMSets, ...keep} = eset;
    return keep;
};

export const langTxt = (useE, e, c) => toStr(useE? (e||c): (c||e)); 

const fixExs = es => toAry(es).filter(e => isObj(e)).map(e => fixeECnts(e));

export const usePopExer = (props) => {
    const [ popExer, _setPopExer ] = useStateGST(props, 'PopExer', 0);
    const [exModified, _setExModified] = useStateGST(props, 'PopExerMod', 0);

    const setExModified = m => { _setExModified(m); };

    const setPopExer = (p, onUnpop) => {
        if(p){
            _setPopExer(p);
            return;
        }
        const unpop = () => {
            setExModified(0); 
            _setPopExer(0);
            isFunc(onUnpop) && onUnpop();  
        };
        if(exModified){
            popExEdQuit(props.dispatch, unpop);
        }else{
            unpop();
        }
    };

    const goPopExer = (pub, fields, showEn, assign, isNewE) => {
        setPopExer({pub, fields, showEn, assign, isNewE});
    };
    return {popExer, setPopExer, goPopExer, exModified, setExModified};
};

const popExEdQuit = (dispatch, onExit) => {
    //<RenderWarning confirm={""} msg={"warning.warning_confirm_without_saving"}/>
    const [holder, close] = alertHolder(dispatch);
    const clickClose = e => { UI.stopEvent(e); close(); };
    const clickExit = e => { onExit(); clickClose(e); };
    holder.alert = <AlertExEdQuit {...{clickClose, clickExit}} />;
    dispatch(gst_pushAlert(holder.alert));
};

export const AlertExEdQuit = (props) => {
    const { clickExit, clickClose, confirmTxt, cancelTxt, bodyTxt} = props;
    const [t, uiEn, UILang, setUILang, ut] = useUILang();
    const confirm = confirmTxt?confirmTxt:'leave';
    const cancel = cancelTxt?cancelTxt:'cancel';
    const bodyMsg = bodyTxt?bodyTxt:'warning.warning_confirm_without_saving';
    return (
    <CpSharedModal show={true} scrollable={true} 
        header={<SharedModalHeader title={t('warning.warning_tips')} iconPath={IconList.general.alert} handleClose={clickClose}/>} 
        footer={<div className={"d-flex gap-2 justify-content-center w-100"}>
            <Button variant="gray-body-color w-100 border" onClick={clickClose}>{t(cancel)}</Button>
            <Button variant={`${confirm === "delete"? "danger": "exercise-third-btn"} btn-block w-100`}
                onClick={clickExit}>{t(confirm)}</Button>
        </div>}
    >
        <CpSettingModalTabContainer gap={3}><CpSettingModalSection className={"flex-column"}>
            <div className={"semi-bold"} dangerouslySetInnerHTML=
                {{__html: sanitize(t(bodyMsg))}}></div>
        </CpSettingModalSection></CpSettingModalTabContainer>
    </CpSharedModal>
    );
};

export const xapiPost = async (dispatch, type, paras) => {
    await asyncApiCall_(dispatch, '/postXAPI', {...paras, type, });
};