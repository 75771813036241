import React, {  } from 'react';
import { IconList } from '../../consts/ATIconListToUsePoc';
import SVG from 'react-inlinesvg';

const CpATIcoBtn = props => {
  const { src, txt, onClick, exStyle } = props;
  const enable = (onClick?true:false);
  return (<div className={'ATIcoBtn '+(enable?'clickable':'ATIcoBtnDisable')} onClick={onClick || undefined}>
      {src && svgIcon2(src, enable ?"#2995cd":"#b4b4b4",{width:"20px",marginRight:txt?'2px':'0', ...exStyle})}
      {txt?<span className='vam'>{txt}</span>:''}
    </div>);
};
export default CpATIcoBtn;

export const cpATIcoBtn_ = (src, txt, onClick, exStyle={}) => <CpATIcoBtn {...{src, txt, onClick, exStyle}} />;

// svg color only use fontColor to adjust
// remove iconColor, remove btnClass, remove fontClass
export const iconButton = (caption="caption", icon="", fontColor="#FFFFFF", bgColor="#AAAAAA",
  onClick, leftIcon=true, iconStyle={}, buttonStyle={}, enable=true) => {
  const onClick_ = (enable && onClick) || undefined;
  const enable_ = onClick_ && enable;
  return <div id={"iB1"+caption} className={"iconButton " + (enable_? " clickable ": " unclickable ")}
      style={{color: fontColor, backgroundColor: enable_? bgColor: "#A7A7A7", ...buttonStyle}} onClick={onClick_ }>
      {icon && leftIcon && svgIcon2(icon, fontColor, iconStyle)}
      <div id={"iB2"+caption} className={" usel "}>{caption}</div>
      {icon && !leftIcon && svgIcon2(icon, fontColor, iconStyle)}
  </div>
};

// remove caption, remove fill color, swap font color and style
export const svgIcon2 = (path, fontColor="black", myStyle={})=>{
//export const svgIcon2 = (src = "", width = null, height = null, title = null) => {
  const pp = path.split("/");
  //const pp = 'http://localhost:3001'+src;
  
  return <div className={"svg-icon"} style={{display:"flex", color:fontColor}}>
      <SVG src={process.env.REACT_APP_WEBROOT + 'static/icon/' + IconList[pp[0]][pp[1]]}
      style={myStyle} onError={(error) => { } }/>
  </div>
};

