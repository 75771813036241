import {useEffect, useMemo, useRef, useState} from "react";
import {
    DONUT_DEFAULT_SEGMENT_COLOR,
    getChartColor,
    getProgressStatusColor,
    getSchoolMonthIdByValue,
    LineBarType,
    LineBarValueType,
} from "../../EPReport/ChartTypeConst";

import ChartLineBar from "./ChartLineBar";
import CpLineBarIndicator from "./CpLineBarIndicator";
import { getUUID } from "../../_components/CpGetUUID";
import { useUILang } from "../../../AppExPf/utils/useUILang";
import { aryLen, isAry, toAry, toFix1, toObj, toStr } from "../../../libs/libType";
import { getTransMap } from "../../../libs/libTransMap";

const ChartProgressByMonth = (props) => { // base on poc ProgressByMonthChart
    //const [isThr, isStt, uRole ] = expfRoles(props);
    const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();
    const chartRef = useRef();

    const isStt = props?.isStt;
    const {lineBarType, type, id, className} = props;

    const data = useMemo(() => toAry(props.data), [props.data]); //EX-1689
    const items = useMemo(() => toAry(props.items), [props.items]); //EX-1689
    const selectAllItems = useMemo(() => toAry(items).map(i => i.id), [items]);

    const { isKeyStage, rptKeyStage } = props;
	const {KSYears, KSClss, KSStus} = toObj(rptKeyStage);

    const [selectedIndicators, setSelectedIndicators] = useState(selectAllItems);
    useEffect(() => { setSelectedIndicators(selectAllItems); }, [items]);
    const selectIndicator = e => {
        const value = e?.currentTarget?.getAttribute("group-item-data");
        if (!value) return;
        setSelectedIndicators(updateArrayValue([...selectedIndicators], value));
    };

/* useEffect(() => { // need full redraw, dont just toggle vis
    if (chartRef.current) chartRef.current.handleShowData({key:"group-item-data", value: selectedIndicators});
}, [selectedIndicators, data]); ////EX-1689 */

    const rpGpLbl = rg => isKeyStage? clsInf2SchYr(KSClss, rg): getSchoolMonthIdByValue(rg, t); 
    const dataDeps = [type, data, selectedIndicators, items, uiEn];
    const lineData = useMemo(() => dataToLines(type, data, selectedIndicators, items, rpGpLbl), dataDeps);
    const barData = useMemo(() => dataToBars(type, data, selectedIndicators, items, rpGpLbl), dataDeps);

//useEffect(() => {
    //console.log( 'dataDeps eff', {type, selectedIndicators, data, items} );
    //console.log( {lineData, barData} );
//}, dataDeps);

    const drawLine = (lineBarType === LineBarType.LINE);
    return <>{aryLen(items)? <CpLineBarIndicator xItems={items} onClick={selectIndicator}/>: ""}
        <div id={toStr(id)} className={'ChartProgressByMonth '+toStr(className)+' progress-by-month-container'}>
            <div className={"overflow-hidden w-100 h-100 "}>
                <div className={"max-size-chart-400 text-center"}>
                    <ChartLineBar key={'lineBarType+""+DCF'} {...{lineBarType, isKeyStage, rptKeyStage,
                        title: t(drawLine? "report-average-score-percentage": getTransMap("report-no-of-assignment", isStt)),
                        lineBarValueType: (drawLine? LineBarValueType.PERCENTAGE: LineBarValueType.VALUE),
                        lineData, barData, ref: chartRef}} />
                </div>
            </div>
        </div>
    </>
};
/*
<ChartLineBar key={lineBarType+""+DCF} {...{lineBarType, isKeyStage, rptKeyStage,
    title: t(drawLine? "report-average-score-percentage": getTransMap("report-no-of-assignment", isStt)),
    lineBarValueType: (drawLine? LineBarValueType.PERCENTAGE: LineBarValueType.VALUE),
    data: renderChartData(), ref: chartRef}} />

<ChartLineBar key={forceRenew()} {...{lineBarType, isKeyStage, rptKeyStage,
    title: t(drawLine? "report-average-score-percentage": getTransMap("report-no-of-assignment", isStt)),
    lineBarValueType: (drawLine? LineBarValueType.PERCENTAGE: LineBarValueType.VALUE),
    data: renderChartData(), ref: chartRef}} />
*/

export default ChartProgressByMonth;

const updateArrayValue = (array, value) => { // base on poc updateArrayValue.ts
    const index = array.indexOf(value);
    if (index !== -1) {
        array.splice(index, 1);        // Value exists in the array, so remove it
    } else {
        array.push(value);        // Value doesn't exist in the array, so add it
    };
    return array;
};

const clsInf2SchYr = (cInfs, cId) => {
    const c = cInfs?.[cId];
    return toStr(c?.year || cId);
};


const dataToLines = (type, data, selectedIndicators, items, rpGpLbl) => {
//console.log('dataToLines', {type, selectedIndicators, data});
    const tmpId = getUUID();
    return toAry(data).map( item => {
        const {referenceId, rpGroup, progress, averageScore, overview} = toObj(item);
        const avgP = toFix1(averageScore.percentage);
        const colorIndex = items.findIndex(item => item.id === referenceId);
        return {
            id: referenceId || tmpId,
            title: `${avgP}`,
            referenceId: rpGpLbl(rpGroup),
            value: avgP,
            color: ["a", "sc"].includes(type)? getChartColor(colorIndex): DONUT_DEFAULT_SEGMENT_COLOR,
            isHide: (["s"].includes(type) || selectedIndicators.includes(referenceId))? 0: 1,
        }
    });
}

const dataToBars = (type, data, selectedIndicators, items, rpGpLbl) => {
//console.log('dataToBars', {type, selectedIndicators, data});
    const tmpId = getUUID();
    return toAry(data).map( item => {
        const {referenceId, rpGroup, progress, averageScore, overview} = toObj(item);
        const barId = referenceId || tmpId;
        const barTitle = `${toFix1(averageScore?.percentage)}`;
        const barReferenceId = rpGpLbl(rpGroup); 
        const colorIndex = items.findIndex(item => item.id === referenceId);
        const isHide = (type === "a" && selectedIndicators.includes(referenceId))? 0 :1;
        switch (type) {
            case "a":
            case "sc":
                return {..._barData(barId, barTitle, barReferenceId, progress?.value || 0, getChartColor(colorIndex)), isHide};
            case "s":
                return toAry(overview).map(ov => {
                    return _barData( barId, barTitle, barReferenceId, ov.value, getProgressStatusColor(ov.status));
                });
            default:
                return _barData( "", "", "", 0, "");
        }
    }).flat();
};
const _barData = (id, title, referenceId, value, color) => ({id, title, referenceId, value, color});
