import preval from 'preval.macro'
import { formatDate, timeStampGMTToHk } from "./libTime";

const buildDate = preval`module.exports = (new Date()).getTime();`;
const baseYear = 2024;
const GMTToHk_value = timeStampGMTToHk(buildDate);

const getYPreft = (v) => {
    const yPref = (new Date(v).getFullYear()) - baseYear;
    return '' + (yPref > 0 ? yPref: '');
};

//export const webAppVer = 'v' + yPref + formatDate(timeStampGMTToHk(buildDate), 'M.D.HHmm');
//export const webAppVer = 'v' + getYPreft(GMTToHk_value) + formatDate(GMTToHk_value, 'M.D.HHmm');
export const webAppVer = 'v' + getYPreft(GMTToHk_value) + formatDate(GMTToHk_value, 'YYYY.M.D.HHHmm'); //EX-1441

