import { useMemo } from 'react';
import { aTErrDiv1, aTUIMust, preJS } from '../AppUtil';
import { isObj, objEntries, objKeys, objVals, toAry, toObj } from '../../libs/libType';
import { ReduxBind } from '../../saga/ReduxState';
import CpMetaLvTickList, { QSetMSetPerType } from '../components/CpMetaLvTickList';
import { expf2 } from '../../consts/ATSysQType';
import { toUniIdAry } from '../../consts/ATValidate';
import { splitSMId, splitSetMetaIds2D } from '../../consts/ATValidateMetaSet';


const TabQSetMeta = ReduxBind(props => {
    const {dispatch, setTick, fieldErrs, setFields, setField, filter, setFilter, lock, tagMSets, useTagMSets} = props; 

    const qset = toObj(props.qset);
    const qids = qset.QSQIds;
    const opts = toObj(props.opts);
    const qtns = opts.QSQs;

    const {MSMusts, MSOpts} = useMemo(()=>{
      const MSs = toObj(opts.QSMSs); 
      const MSets = toAry(qset.QSMSIds).map(i => MSs[i]).filter(m => m); //EXPF2 ret pre pro
//console.log('useMemo_Qset', {MSs, MSets});

      return {...QSetMSetPerType(MSets)};
    }, [props.qset]);

    const [ppmsid, preMSIds, proMSIds] = useMemo(() => {
      const qs = toUniIdAry(qids).map(i => qtns?.[i]?.p).filter(p => p);
//console.log({qs});
      const preMSIds = toUniIdAry([].concat(...qs.map(q => toAry(q.QMSetPreIds))));
      const proMSIds = toUniIdAry([].concat(...qs.map(q => toAry(q.QMSetProIds))));
      const ppmsid = toUniIdAry([...preMSIds, ...proMSIds]);      
//console.log({ppmsid, preMSIds, proMSIds});
      return [ppmsid, preMSIds, proMSIds];
    }, [qids, qtns]);

    const MSets = useTagMSets(ppmsid);
    const [preTMSs, proTMSs] = useMemo(() => {
      const preTMSs = QSetMSetPerType(toUniIdAry(preMSIds).map(i => MSets[i]).filter(s => s));
      const proTMSs = QSetMSetPerType(toUniIdAry(proMSIds).map(i => MSets[i]).filter(s => s));
//console.log({preTMSs, proTMSs, MSets});
      return [preTMSs, proTMSs];
    },[preMSIds, proMSIds, MSets]);
/*
    const {PerTypePreMSets, PerTypeProMSets, preLvs, proLvs, } = useMemo(() => {
  console.log({QSQMPreSet, QSQMProSet, tagMSets});
      const PerTypePreMSets = msids2PerTypeMSet(toUniIdAry(objKeys(QSQMPreSet)), MSets);
      const PerTypeProMSets = msids2PerTypeMSet(toUniIdAry(objKeys(QSQMProSet)), MSets);
      const preLvs = mapMSetLvs(QSQMPreSet, MSets);
      const proLvs = mapMSetLvs(QSQMProSet, MSets);
      return {PerTypePreMSets, PerTypeProMSets, preLvs, proLvs, };
    }, [QSQMPreSet, QSQMProSet, tagMSets]);
*/

    return <>
      <h4>Metadata Sets from included question(s) {aTUIMust} </h4>
      {aTErrDiv1(fieldErrs?.QSMetaFilters)}
      <CpMetaLvTickList {...{MSMusts, MSOpts, lock, tickLbls:['Filter'], tickss:[filter], setterss:[setFilter]}} />
      {expf2?<>
        <hr/>
        <h4>Prior knowledge</h4>
        <CpMetaLvTickList {...{...preTMSs, lock:1, tickLbls:[''], tickss:[[]], setterss:[0]}} />
        <hr/>
        <h4>Advanced knowledge</h4>
        <CpMetaLvTickList {...{...proTMSs, lock:1, tickLbls:[''], tickss:[[]], setterss:[0]}} />
      </>:''}
    </>;
});
export default TabQSetMeta; 

const MTreeMarkLv = (metas, pMetaId, mBranch, treeLv) => {
  objEntries(mBranch).forEach(([mid, submetas]) => {
    const m = metas[mid];
    if(m){
      m.pMetaId = pMetaId;
      m.treeLv = treeLv; 
    }
    MTreeMarkLv(metas, mid, submetas, treeLv + 1);
  });
};
const mapMSetLvs = (SetMetas, tagMSets) => {
  //console.log('mapMSetLvs', SetMetas, tagMSets);
  const ml2d = objEntries(SetMetas).map(([MSetId, midset]) => {
    const mset = tagMSets[MSetId];
    if(!isObj(mset)) return [];
    MTreeMarkLv(toObj(mset.metas), '', mset.tree2, 0); 
    const metas = toObj(mset.metas);
    return Array.from(midset).map(mid => {
      const m = metas[mid];
      return m? MSetId+','+m.treeLv: '';  
    })
  });
  return toUniIdAry([].concat(...ml2d));
};
const msids2PerTypeMSet = (msids, MSets) => {
  const msets = toUniIdAry(msids).map(i => MSets[i]).filter(m => m);
  return QSetMSetPerType(msets);
}

const noop = e => {};

// /getATQSet
const getATQSet_ret = {
  "QSMSs": [
    {
      "heads": [
        {
          "LVNum": "Index - Level 1",
          "nameEn": "School",
          "nameCt": "學校",
          "metaId": "ID - Level 1"
        },
        {
          "LVNum": "Index - Level 2",
          "nameEn": "Key Stage",
          "nameCt": "學習階段",
          "metaId": "ID - Level 2"
        },
        {
          "LVNum": "Index - Level 3",
          "nameEn": "Level",
          "nameCt": "程度",
          "metaId": "ID - Level 3"
        },
        {
          "LVNum": "Index - Level 4",
          "nameEn": "Grade",
          "nameCt": "年級",
          "metaId": "ID - Level 4"
        }
      ],
      "metaSetId": "ms1",
      "MSType": "tb",
      "MSLangEn": 1,
      "MSLangCt": 1,
      "MSNameEn": "ms1",
      "MSNameCt": "ms1"
    },
    {
      "heads": [
        {
          "LVNum": "Index - Level 1",
          "nameEn": "Series",
          "nameCt": "系列",
          "metaId": "ID - Level 1"
        },
        {
          "LVNum": "Index - Level 2",
          "nameEn": "Book",
          "nameCt": "本書",
          "metaId": "ID - Level 2"
        },
        {
          "LVNum": "Index - Level 3",
          "nameEn": "Chapter",
          "nameCt": "章節",
          "metaId": "ID - Level 3"
        },
        {
          "LVNum": "Index - Level 4",
          "nameEn": "Section",
          "nameCt": "部份",
          "metaId": "ID - Level 4"
        },
        {
          "LVNum": "Index - Level 5",
          "nameEn": "Sub-Section",
          "nameCt": "次部份",
          "metaId": "ID - Level 5"
        }
      ],
      "metaSetId": "ms2",
      "MSType": "ge",
      "MSLangEn": 1,
      "MSLangCt": 1,
      "MSNameEn": "ms2",
      "MSNameCt": "ms2"
    }
  ],
  "QSQs": {
    "QCtScroll": {
      "p": {
        "QLangCt": 1,
        "QVer": "1.0",
        "QMSetIds": [],
        "QLangEn": 0,
        "dp": "p",
        "drpu": "U",
        "QId": "QCtScroll"
      },
      "code": "QSQ",
      "QMSetIds": [],
      "QSetId": "Copy3_234234",
      "dp": "d",
      "QId": "QCtScroll"
    },
    "QEnPage": {
      "p": {
        "QLangCt": 0,
        "QVer": "1.0",
        "QMSetIds": [],
        "QLangEn": 1,
        "dp": "p",
        "drpu": "U",
        "QId": "QEnPage"
      },
      "code": "QSQ",
      "QMSetIds": [],
      "QSetId": "Copy3_234234",
      "dp": "d",
      "QId": "QEnPage"
    },
    "Qdraft": {
      "code": "QSQ",
      "d": {
        "QLangCt": 1,
        "QVer": "1.0",
        "QMSetIds": [],
        "QLangEn": 1,
        "dp": "d",
        "drpu": "D",
        "QId": "Qdraft"
      },
      "QMSetIds": [],
      "QSetId": "Copy3_234234",
      "dp": "d",
      "QId": "Qdraft"
    },
    "Qunpub": {
      "p": {
        "QLangCt": 1,
        "QVer": "1.0",
        "QMSetIds": [],
        "QLangEn": 1,
        "dp": "p",
        "drpu": "U",
        "QId": "Qunpub"
      },
      "code": "QSQ",
      "QMSetIds": [],
      "QSetId": "Copy3_234234",
      "dp": "d",
      "QId": "Qunpub"
    },
    "q12a": {
      "p": {
        "QLangCt": 1,
        "QVer": "3.1",
        "QMSetIds": [
          "ms1",
          "ms2"
        ],
        "QLangEn": 1,
        "dp": "p",
        "drpu": "P",
        "QId": "q12a"
      },
      "code": "QSQ",
      "QMSetIds": [
        "ms1",
        "ms2"
      ],
      "QSetId": "Copy3_234234",
      "dp": "p",
      "QId": "q12a"
    },
    "q12b": {
      "p": {
        "QLangCt": 1,
        "QVer": "2.2",
        "QMSetIds": [
          "ms1",
          "ms2"
        ],
        "QLangEn": 1,
        "dp": "p",
        "drpu": "P",
        "QId": "q12b"
      },
      "code": "QSQ",
      "QMSetIds": [
        "ms1",
        "ms2"
      ],
      "d": {
        "QLangCt": 1,
        "QVer": "3.0",
        "QMSetIds": [
          "ms1",
          "ms2"
        ],
        "QLangEn": 1,
        "dp": "d",
        "drpu": "D",
        "QId": "q12b"
      },
      "QSetId": "Copy3_234234",
      "dp": "p",
      "QId": "q12b"
    }
  },
  "pub": {
    "addLogin": "testa",
    "code": "QST",
    "QSQCnt": 2,
    "dp": "p",
    "QSMSIds": [
      "ms1",
      "ms2"
    ],
    "QSKwCt": "dfgdsfg",
    "dateAdd": 1674630822785,
    "QSNameCt": "ccc",
    "QSKwEn": "hshgfds",
    "addUid": "R3kWxBS4HkBZvgxGgYuy9e",
    "ESASStr": "234234",
    "QSMetaFilters": [
      "ms1,0",
      "ms1,1"
    ],
    "QSLangCt": 1,
    "QSNameEn": "eee",
    "modUid": "R3kWxBS4HkBZvgxGgYuy9e",
    "QSLangEn": 1,
    "QSSubjIds": [
      "ss4",
      "ssss5aa"
    ],
    "dateMod": 1674630822785,
    "QSetId": "Copy3_234234",
    "QSVerId": "U2WqB877QRDXDLJ23h88Ax",
    "drpu": "P",
    "modLogin": "testa",
    "QSVer": "6.0",
    "QSQIds": [
      "q12a",
      "q12b"
    ]
  },
  "draft": {
    "addLogin": "testa",
    "code": "QST",
    "QSQCnt": 6,
    "dp": "d",
    "QSMSIds": [
      "ms1",
      "ms2"
    ],
    "QSKwCt": "dfgdsfg",
    "dateAdd": 1676417877669,
    "QSNameCt": "ccc",
    "QSKwEn": "hshgfds",
    "addUid": "R3kWxBS4HkBZvgxGgYuy9e",
    "ESASStr": "234234",
    "QSMetaFilters": [
      "ms1,0",
      "ms1,1"
    ],
    "QSLangCt": 1,
    "QSNameEn": "eee",
    "modUid": "R3kWxBS4HkBZvgxGgYuy9e",
    "QSLangEn": 1,
    "QSSubjIds": [
      "ss4",
      "ssss5aa"
    ],
    "dateMod": 1676421879642,
    "QSetId": "Copy3_234234",
    "QSQIds": [
      "q12b",
      "Qunpub",
      "Qdraft",
      "QCtScroll",
      "QEnPage",
      "q12a"
    ],
    "QSVerId": "Y7RCBZywQJR63BNTG91aET",
    "drpu": "D",
    "modLogin": "testa",
    "QSVer": "7.5"
  },
};
const getATQSet_ret_unsed = {
  "auth": {
    "APIVer": "23.10.11.a",
    "apiCounter": 10,
    "svrTime": {
      "str": "24 03 17-15 27 19",
      "GMTSec": 1710689239
    },
    "uid": "",
    "ATUserId": "R3kWxBS4HkBZvgxGgYuy9e",
    "sessionId": "66c6b48d-b6ee-4839-a6e0-5670c49e0e8d",
    "maintenanceMode": "",
    "maintenanceMsg": [],
    "maintenanceId": 0,
    "_timing": [
      [
        "Extract SessId",
        1710689239324,
        0
      ],
      [
        "SessId Locked",
        1710689239334,
        10
      ],
      [
        "SessId Loaded",
        1710689239336,
        12
      ],
      [
        "API Core enter",
        1710689239336,
        12
      ],
      [
        "API Core return",
        1710689239362,
        38
      ],
      [
        "Session Saved",
        1710689239371,
        47
      ]
    ]
  },
  "errs": [],
  "ATSubjects": [
    {
      "ATSubjectId": "as9",
      "ATSubjectName": "hhhhh"
    },
    {
      "ATSubjectId": "sa888",
      "ATSubjectName": "asss"
    },
    {
      "ATSubjectId": "sj1",
      "ATSubjectName": "sj1"
    },
    {
      "ATSubjectId": "sj2",
      "ATSubjectName": "sj2"
    },
    {
      "ATSubjectId": "sj3",
      "ATSubjectName": "sj3"
    },
    {
      "ATSubjectId": "ss2",
      "ATSubjectName": "ss2 SS2"
    },
    {
      "ATSubjectId": "ss3",
      "ATSubjectName": "SS3"
    },
    {
      "ATSubjectId": "ss4",
      "ATSubjectName": "SS4"
    },
    {
      "ATSubjectId": "ss4a",
      "ATSubjectName": "ssss 444 4444444 44 4444f4f 44f4f44"
    },
    {
      "ATSubjectId": "ss6",
      "ATSubjectName": "ssss6666"
    },
    {
      "ATSubjectId": "ss7",
      "ATSubjectName": "ssss8777"
    },
    {
      "ATSubjectId": "ssss5aa",
      "ATSubjectName": "sss55555"
    }
  ],
  "mySjIds": [
    "ss4",
    "ss4a",
    "ss6",
    "ss7",
    "ssss5aa",
    "sj1",
    "sj2"
  ],
};